<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :form-initialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
  >
    <template slot="certMd5" slot-scope="{item, formModel}">
      {{ formModel[item.prop] }}
    </template>
    <template slot="certContent" slot-scope="{item, formModel}">
      <FileUpload
        :column="item"
        v-bind:formData="formModel"
        :hideFile="true"
        :input="{accept: '.pem'}"
        :acceptRegExp="acceptRegExp"
        :uploadUrl="uploadUrl"
      >
        <el-button type="primary" size="mini">上传证书文件</el-button>
      </FileUpload>
      <el-input type="textarea" :rows="20" placeholder="请输入内容" @input="onInput(item, formModel)" v-model.trim="formModel[item.value]"></el-input>
    </template>
  </rest>
</template>

<script>

import Rest from '@/components/Spring/Rest'
import FileUpload from '@/components/Widget/FileUpload.vue'
import md5 from 'js-md5'
import utils from '@/common/utils'

export default {
  name: 'pkgConfigs',
  components: { Rest, FileUpload },

  data () {
    return {
      remote: {
        path: 'channel',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        certMd5: '',
        certContent: ''
      },
      formRules: {
        name: [{ required: true, trigger: ['submit', 'blur'], message: '请填写证书名称' }],
        appId: [{ required: true, trigger: ['submit', 'blur'], message: '请填写游戏ID' }],
        channelId: [{ required: true, message: '请填写channelId' }],
        adLostRate: [{ required: true, message: '请填写广告回传概率' }],
        certContent: [{ required: true, message: '请填写证书内容' }]
      },
      listeners: {
      },
      channelOptions: []
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '证书名称', prop: 'name' },
        { label: '包名', prop: 'pkgName' },
        { label: '游戏ID', prop: 'appId' },
        { label: '渠道Id', prop: 'channelId' },
        { label: '广告回传概率', prop: 'adLostRate' },
        { label: '证书md5', prop: 'certMd5', type: 'slot' },
        { label: '证书内容', prop: 'certContent', value: 'certContent', type: 'slot' },
        { label: '创建时间', prop: 'createdDate', type: 'datetime', hiddenForm: true },
        { label: '最后修改时间', prop: 'lastModifiedDate', type: 'datetime', hiddenForm: true }
      ]
    },
    acceptRegExp () {
      return /.pem$/
    }
  },
  methods: {
    onInput: utils.debounce((item, form) => {
      const value = form[item.prop]
      form = Object.assign(form, { certMd5: value ? md5(value.trim()) : '' })
      // console.log(value, form)
    }, 300),
    uploadUrl (file, column, formData) {
      console.log(file)
      // 上传时 默认文件名 为包名
      formData.pkgName = file.name.replace(this.acceptRegExp, '').replaceAll('.cert', '')
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      return new Promise((resolve) => {
        reader.onload = (e) => {
          console.log('解析成功', e)
          formData.certMd5 = md5(e.target.result.trim())
          resolve(e.target.result.trim())
        }
        reader.onerror = () => {
          console.error('解析失败')
          resolve('')
        }
      })
    }
  },
  mounted () {}
}
</script>

<style scoped>
::v-deep.file-upload .upload {
  width: max-content;
  height: max-content;
  border-color: transparent;
  cursor: pointer;
}
</style>
